import { http } from 'common/helpers/axios/axios-helper';
import { UserAndTenantsModel } from '../model/tenant-selector.model';

const basePath = '/User';

async function getUserAndTenants(userId: number) {
  return await http.get<UserAndTenantsModel>(`${basePath}/${userId}/alltenants`);
}

async function putTenants(userId: number, tenants: number[]) {
  let data = { userId: userId, tenantIds: tenants };
  return await http.put(`${basePath}/updateTenantUser`, data);
}

export const TenantSelectorService = { getUserAndTenants, putTenants };
