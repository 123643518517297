import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import SaveButton from 'common/partials/SaveButton';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { TenantManagementModel as Model } from '../model/tenant-management.model';
import { TenantManagementService as Service } from '../services/tenant-management.service';

export function CreateEditTenant() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = Yup.object().shape({
    name: Yup.string().required(t('crud.validators.requiredField')).max(70, t('crud.validators.maxLength_70')),
    description: Yup.string().required(t('crud.validators.requiredField')).max(512, t('crud.validators.maxLength_512')),
  });

  let initialData: Model = {
    id: parseInt(id ?? '0'),
    name: '',
    description: '',
    color: '#009ef7',
    isActive: true,
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          id: data.id,
          name: values.name,
          description: values.description,
          color: values.color,
          isActive: values.isActive,
        };
        if (id) {
          const result = await Service.putTenant(body);
          if (result && !result?.hasErrors) {
            toast.success(`${t('crud.update.successMessage')}`);
          } else {
            toast.error(`${t('crud.update.errorMessage')}`);
          }
        } else {
          const result = await Service.postTenant(body);
          if (result && !result?.hasErrors) {
            toast.success(`${t('crud.create.successMessage')}`);
            navigate(`/security/tenantmanagement`);
          } else {
            toast.error(`${t(`error.${result?.errorCode}`)}`);
          }
        }
      } catch (error) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await Service.getTenantById(id);
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-10">
                  <label className="required form-label">{t('tenant.form.label.name')}</label>
                  <input
                    type="text"
                    name="name"
                    maxLength={70}
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('tenant.form.placeholder.name')}
                    value={formik.values.name ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.name}</div>
                  )}
                </div>
                <div className="col-md-6 mb-10">
                  <label className="required form-label">{t('tenant.form.label.description')}</label>
                  <input
                    type="text"
                    maxLength={512}
                    name="description"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('tenant.form.placeholder.description')}
                    value={formik.values.description ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.description}</div>
                  )}
                </div>
                <div className="col-md-3 mb-10">
                  <label className="required form-label">{t('tenant.form.label.color')}</label>
                  <input
                    type="color"
                    name="color"
                    style={{ width: 100 }}
                    className="form-control form-control-lg form-control-solid form-control-color"
                    placeholder={t('tenant.form.placeholder.color')}
                    value={formik.values.color ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.color && formik.errors.color && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.color}</div>
                  )}
                </div>
                {id && (
                  <div className="col-md-3 mb-10">
                    <label className="form-label">{t('tenant.form.label.status')}</label>
                    <div className="form-check form-check-solid form-switch">
                      <input
                        className="form-check-input w-45px h-30px"
                        type="checkbox"
                        id="isActive"
                        name="isActive"
                        checked={formik.values.isActive ?? false}
                        onChange={formik.handleChange}
                      />
                    </div>
                    {formik.values.isActive ? (
                      <div className="text-muted fs-7 mt-5">{t('generalMessages.active')}</div>
                    ) : (
                      <div className="text-muted fs-7 mt-5">{t('generalMessages.inactive')}</div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <Link className="btn btn-light btn-active-light-primary me-2" to="/security/tenantmanagement">
                {t('crud.create.buttonCancel')}
              </Link>
              <SaveButton type="submit" loading={isLoading} />
            </div>
          </div>
        </form>
      </MainLayout>
    </MasterLayout>
  );
}
