import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './i18n/en-us.json';
import pt from './i18n/pt-br.json';
import es from './i18n/es-es.json';

// TODO: Export languages file to split different languages and create type safety when accessing language props

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'br',
    resources: {
      us: {
        translation: {
          menu: {
            home: 'Home',
            dashboard: 'Dashboard',
            charts: 'Charts',
            projectStatus: 'Project Status',
            security: 'Security',
            example: 'Samples',
            crud: {
              title: 'CRUD',
              name: 'Name',
              decimalValue: 'Decimal Value',
              dateOfBirth: 'Date of Birth',
              age: 'Age',
            },
            complexCrud: {
              title: 'Complex CRUD',
              name: 'Name',
              decimalValue: 'Decimal value',
              dateOfBirth: 'Date of birth',
              handicapped: 'Handicapped',
              termsAcceptance: 'Terms of acceptance',
              avatar: 'Image',
            },
            tenantManagement: {
              menu: 'Tenant Management',
              title: 'Tenant Management',
              add: 'Add tenant',
              edit: 'Edit tenant',
            },
            userManagement: {
              menu: 'User Management',
              title: 'User Management',
              add: 'Add User',
              edit: 'Edit User',
            },
            groupManagement: {
              menu: 'Group Management',
              title: 'Group Management',
              add: 'Add Group',
              edit: 'Edit Group',
            },
            apiClients: 'API Clients',
            settings: 'Configuration',
            userProfile: 'User Profile',
            generalSettings: 'General Settings',
            basic: {
              title: 'Basic CRUD',
              add: 'Add Data',
              edit: 'Edit Data',
            },
            complexdatabase: {
              menu: 'Complex CRUD Database',
              title: 'Complex CRUD Database',
              add: 'Add Data',
              edit: 'Edit Data',
            },
            complexfrontend: {
              menu: 'Complex CRUD Frontend',
              title: 'Complex CRUD Frontend',
              add: 'Add Data',
              edit: 'Edit Data',
            },
            robotType: {
              menu: 'Robot Type',
              title: 'Robot Type',
              add: 'Add Robot Type',
              edit: 'Edit Robot Type',
            },
            countries: {
              menu: 'Countries',
              title: 'Countries',
              add: 'Add Country',
              edit: 'Edit Country',
            },
            sites: {
              menu: 'Sites',
              title: 'Sites',
              add: 'Add Site',
              edit: 'Edit Site',
            },
            operationsystem: {
              menu: 'Operation System',
              title: 'Operation System',
              add: 'Add Operation System',
              edit: 'Edit Operation System',
            },
            departaments: {
              menu: 'Departaments',
              title: 'Departaments',
              add: 'Add Departament',
              edit: 'Edit Departament',
            },
            processes: {
              menu: 'Process',
              title: 'Processes',
              add: 'Add Process',
              edit: 'Edit Process',
            },
            technologies: {
              menu: 'Technologies',
              title: 'Technologies',
              add: 'Add Technology',
              edit: 'Edit Technology',
            },
            machineType: {
              title: 'Machine Type',
              add: 'Add Machine Type',
              edit: 'Edit Machine Type',
            },
            machine: {
              title: 'Machine',
              add: 'Add Machine',
              edit: 'Edit Machine',
            },
            errorreason: {
              menu: 'Error Reasons',
              title: 'Error Reasons',
              add: 'Add Error Reasons',
              edit: 'Edit Error Reasons',
            },
            executions: {
              menu: 'Executions',
              title: 'Executions',
            },
            agentVersions: 'Agent versions',
            events: 'Events',
            machineActivity: 'Machine Activity',
            robotExecutions: 'Robot Executions',
            schedule: 'Schedule',
            detailScreen: 'Detail Screen',
            masterData: 'Master Data',
            infrastructure: 'Infrastructure',
            language: 'Language',
            preferences: 'Preferences',
            general: 'General',
            signOut: 'Sign Out',
          },

          generalMessages: {
            yes: 'Yes',
            no: 'No',
            all: 'All',
            active: 'Active',
            inactive: 'Inactive',
            addUser: 'Add User',
            select: 'Select',
            selectAll: 'Select All',
            sessionExpiresIn: 'Session expires in:',
            sessionExpired: 'Your session has expired. Please refresh page.',
            signin: 'Sign In',
            connected: 'Connected',
            disconnected: 'Disconnected',
            edit: 'Edit',
            delete: 'Delete',
            deactivate: 'Deactivate',
            download: 'Download',
            apply: 'Apply',
            cancel: 'Cancel',
            filter: 'Filter',
            filterOptions: 'Filter Options',
            noOptions: 'No option found',
            status: 'Status',
            start: 'Start',
            stop: 'Stop',
            refresh: 'Refresh',
            screenshot: 'Screenshot',
            copy: 'Copy',
            copied: 'Copied!',
            noPermission: 'You do not have permission',
            execute: 'Execute',
            errors: {
              503: 'The feature that you are trying to access are not enabled. Contact administrator.',
            },
          },
          login: {
            title: 'Welcome to',
            form: {
              username: {
                label: 'Username',
                placeholder: 'Enter your username',
              },
              password: {
                label: 'Password',
                placeholder: 'Enter your password',
              },
            },
            footer: 'Powered by',
            loading: {
              title: 'Welcome',
              subtitle: 'Please wait as we log into your account...',
            },
            success: 'Login successful!',
          },

          units: {
            prefix: '$',
            thousandSeparator: ',',
            decimalSeparator: '.',
          },

          toolbar: {
            filter: 'Filter',
            filterOptions: 'Filter Options',
            status: 'Status:',
            buttonApply: 'Apply',
            buttonCancel: 'Cancel',
          },

          dateFormat: {
            locale: 'en',
            momentTimezone: 'America/New_York',
          },

          topbar: {
            system: {
              version: 'Version: ',
            },
            userMenu: {
              language: 'Language',
              signOut: 'Sign Out',
              myProfile: 'My Profile',
            },
          },

          crud: {
            common: {
              loading: 'Loading...',
              wait: 'Please wait...',
            },
            create: {
              buttonSave: 'Save',
              buttonCancel: 'Cancel',
              successMessage: 'Entry successfully created',
              errorMessage: 'An error occurred while saving entries. Please, try again later.',
            },
            read: {
              errorMessage: 'An error occurred while trying to fetch the list. Please, try again later.',
            },
            update: {
              buttonSave: 'Save',
              buttonCancel: 'Cancel',
              successMessage: 'Entry successfully updated',
              errorMessage: 'An error occurred while uploading entries. Please, try again later.',
            },
            delete: {
              buttonSave: 'Delete',
              buttonCancel: 'Cancel',
              questionMessage: 'Are you sure you want to delete this entry?',
              successMessage: 'Entry deleted successfully!',
              errorMessage: 'An error occurred while deactivating entries. Please, try again later.',
            },
            deactivate: {
              buttonSave: 'Deactivate',
              questionMessage: 'Are you sure you want to deactivate the record?',
              successMessage: 'Record deactivated successfully!',
              errorMessage: 'An error occurred during the deactivation process. Please try again later.',
            },
            download: {
              buttonSave: 'Download',
              questionMessage: 'Are you sure you want to download the file?',
              successMessage: 'Record downloaded successfully!',
              errorMessage: 'An error occurred during the download process. Please try again later.',
            },
            start: {
              successMessage: 'Robot execution process started successfully!',
              errorMessage: 'An error occurred while starting the robot. Please try again.',
            },
            stop: {
              successMessage: 'Execution interrupted successfully!',
              errorMessage: 'An error occurred while trying to stop. Please try again.',
            },
            refresh: {
              successMessage: 'Refresh completed successfully!',
              errorMessage: '"An error occurred while trying to refresh. Please try again.',
            },
            validators: {
              requiredField: 'This field is required.',
              passwordMatch: 'Passwords must match.',
              passwordContains:
                'Passwords must have at least one number, one uppercase letter and one non alphanumeric character.',
              onlyLettersOrNumbers: 'Must contain only letters or numbers.',
              email: 'Must be a valid email.',
              minStartDate: 'The start date cannot be less than the current date.',
              minEndTime: 'The end time cannot be less than the start time.',
              minTwoWords: 'Must have at least two words.',
              minLength_5: 'Must be at least 5 characters.',
              minLength_6: 'Must be at least 6 characters.',
              minLength_15: 'Must be at least 15 characters.',
              minLength_32: 'Must be at most 32 characters.',
              maxLength_25: 'Must be at most 25 characters.',
              maxLength_70: 'Must be at most 70 characters.',
              maxLength_512: 'Must be at most 512 characters.',
              maxLength_1024: 'Must be at most 1024 characters.',
            },
          },

          emptyOption: 'Select an option',

          searchInput: {
            placeholder: 'Search',
          },

          datatable: {
            pagination: {
              title: 'Rows per page',
              of: 'of',
            },
            selectedItems: {
              all: 'All',
              singular: 'selected item',
              plural: 'selected items',
            },
            noData: 'No data available',
          },

          schedules: {
            runningStatus: {
              Scheduled: 'Scheduled',
              NotRunning: 'Not Run',
              Running: 'Running',
              Success: 'Success',
              Stopped: 'Stopped',
              Error: 'Error',
              Suspect: 'Under Suspect',
              Conflict: 'Overlay',
            },
          },

          daysOfWeek: {
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
          },

          enum: {
            agentlogstatus: {
              NotRunning: 'Not Running',
              Running: 'Running',
              Success: 'Success',
              Stopped: 'Stopped',
              Error: 'Error',
              Suspect: 'Suspect',
              Conflict: 'Conflict',
              Triggered: 'Triggered',
            },
          },

          time: {
            complete: {
              second: 'second(s)',
              minute: 'minute(s)',
              hour: 'hour(s)',
              day: 'day(s)',
              month: 'month(s)',
              year: 'year(s)',
            },
            abbreviated: {
              second: 'second(s)',
              minute: 'minute(s)',
              hour: 'hour(s)',
              day: 'day(s)',
              month: 'month(s)',
              year: 'year(s)',
            },
          },

          alerts: {
            notification: 'Notification',
            notifications: 'Notifications',
            noViewed: 'No Read(s)',
            department: 'Department(s)',
            empty: 'No notifications available',
            viewAll: 'View All',
          },
        },
      },
      br: {
        translation: {
          menu: {
            home: 'Início',
            dashboard: 'Dashboard',
            charts: 'Gráficos',
            projectStatus: 'Status do Projeto',
            security: 'Segurança',
            example: 'Exemplos',
            crud: {
              title: 'CRUD',
              name: 'Nome',
              decimalValue: 'Valor decimal',
              dateOfBirth: 'Data de aniversário',
              age: 'Idade',
            },
            complexCrud: {
              title: 'CRUD Complexo',
              name: 'Nome',
              decimalValue: 'Valor decimal',
              dateOfBirth: 'Data de aniversário',
              handicapped: 'Deficiente',
              termsAcceptance: 'Termos de aceite',
              avatar: 'Imagem',
            },
            tenantManagement: {
              title: 'Gerenciamento de Tenants',
              add: 'Adicionar tenant',
              edit: 'Editar tenant',
            },
            userManagement: {
              title: 'Gerenciamento de Usuários',
              add: 'Adicionar usuário',
              edit: 'Editar usuário',
            },
            groupManagement: {
              title: 'Gerenciamento de Grupos',
              add: 'Adicionar Grupo',
              edit: 'Editar Grupo',
            },
            apiClients: 'Clientes API',
            settings: 'Configurações',
            userProfile: 'Perfil de Usuário',
            healthCheck: 'Health Check',
            generalSettings: 'Configurações Gerais',
            basic: {
              title: 'CRUD Básico',
              add: 'Adicionar Dados',
              edit: 'Editar Dados',
            },
            complexdatabase: {
              title: 'CRUD Complexo Banco de Dados',
              add: 'Adicionar Dados',
              edit: 'Editar Dados',
            },
            complexfrontend: {
              title: 'CRUD Complexo Frontend',
              add: 'Adicionar Dados',
              edit: 'Editar Dados',
            },
            robotType: {
              menu: 'Tipo de Robô',
              title: 'Tipo de Robô',
              add: 'Adicionar Tipo de Robô',
              edit: 'Editar Tipo de Robô',
            },
            countries: {
              menu: 'Países',
              title: 'Países',
              add: 'Adicionar Países',
              edit: 'Editar Países',
            },
            sites: {
              menu: 'Locais',
              title: 'Locais',
              add: 'Adicionar Local',
              edit: 'Editar Local',
            },
            operationsystem: {
              menu: 'Sistema Operacional',
              title: 'Sistema Operacional',
              add: 'Adicionar Sistema Operacional',
              edit: 'Editar Sistema Operacional',
            },
            departaments: {
              menu: 'Departamentos',
              title: 'Departamentos',
              add: 'Adicionar Departamento',
              edit: 'Editar Departamento',
            },
            processes: {
              menu: 'Processo',
              title: 'Processos',
              add: 'Adicionar Processo',
              edit: 'Editar Processo',
            },
            technologies: {
              menu: 'Tecnologias',
              title: 'Tecnologias',
              add: 'Adicionar Tecnologia',
              edit: 'Editar Tecnologia',
            },
            machineType: {
              title: 'Tipo de Máquina',
              add: 'Adicionar Tipo de Máquina',
              edit: 'Editar Tipo de Máquina',
            },
            machine: {
              title: 'Máquina',
              add: 'Adicionar Máquina',
              edit: 'Editar Máquina',
            },
            errorreason: {
              menu: 'Causas de erros',
              title: 'Causas de erros',
              add: 'Adicionar Causas de erros',
              edit: 'Editar Causas de erros',
            },
            executions: {
              menu: 'Execuções',
              title: 'Execuções',
            },
            agentVersions: 'Versionamento de Agentes',
            events: 'Eventos',
            machineActivity: 'Atividade de Máquinas',
            robotExecutions: 'Execuções de Robôs',
            schedule: 'Agendamento',
            detailScreen: 'Tela de Detalhes',
            infrastructure: 'Infraestrutura',
            masterData: 'Cad. Auxiliares',
            language: 'Idiomas',
            preferences: 'Preferências',
            general: 'Geral',
            signOut: 'Sair',
          },

          generalMessages: {
            yes: 'Sim',
            no: 'Não',
            all: 'Todos',
            active: 'Ativo',
            inactive: 'Inativo',
            select: 'Selecione',
            selectAll: 'Selecionar todos',
            sessionExpiresIn: 'Sessão expira em:',
            sessionExpired: 'Sua sessão expirou. Por favor atualize a página.',
            signin: 'Entrar',
            connected: 'Conectado',
            disconnected: 'Desconectado',
            edit: 'Editar',
            delete: 'Excluir',
            deactivate: 'Desativar',
            download: 'Download',
            apply: 'Aplicar',
            cancel: 'Cancelar',
            filter: 'Filtrar',
            filterOptions: 'Opções de Filtro',
            noOptions: 'Nenhuma opção encontrada',
            status: 'Status',
            start: 'Iniciar',
            stop: 'Parar',
            refresh: 'Atualizar',
            screenshot: 'Screenshot',
            copy: 'Copiar',
            copied: 'Copiado!',
            noPermission: 'Você não tem permissão',
            execute: 'Executar',
            errors: {
              503: 'O recurso que você está tentando acessar não está habilitado. Contate o administrador.',
            },
          },
          login: {
            title: 'Bem vindo(a) ao',
            form: {
              username: {
                label: 'Usuário',
                placeholder: 'Insira seu usuário',
              },
              password: {
                label: 'Senha',
                placeholder: 'Insira sua senha',
              },
            },
            footer: 'Desenvolvido por',
            loading: {
              title: 'Bem vindo(a),',
              subtitle: 'Aguarde enquanto fazemos login na sua conta...',
            },
            success: 'Login realizado com sucesso!',
          },

          units: {
            prefix: 'R$',
            thousandSeparator: '.',
            decimalSeparator: ',',
          },

          dateFormat: {
            locale: 'pt-br',
            momentTimezone: 'America/Sao_Paulo',
          },

          topbar: {
            system: {
              version: 'Versão: ',
            },
            userMenu: {
              language: 'Idioma',
              signOut: 'Sair',
              myProfile: 'Meu Perfil',
            },
          },

          crud: {
            common: {
              loading: 'Carregando...',
              wait: 'Aguarde...',
            },
            create: {
              buttonSave: 'Salvar',
              buttonCancel: 'Cancelar',
              successMessage: 'Registro criado com sucesso',
              errorMessage: 'Aconteceu algum erro durante o processo. Tente novamente mais tarde.',
            },
            read: {
              errorMessage: 'Aconteceu algum erro durante o processo de carregamento. Tente novamente mais tarde.',
            },
            update: {
              buttonSave: 'Salvar',
              buttonCancel: 'Cancelar',
              successMessage: 'Registro atualizado com sucesso',
              errorMessage: 'Aconteceu algum erro durante o processo de atualização. Tente novamente mais tarde.',
            },
            delete: {
              buttonSave: 'Excluir',
              buttonCancel: 'Cancelar',
              questionMessage: 'Tem certeza que deseja excluir o registro?',
              successMessage: 'Registro excluído com sucesso!',
              errorMessage: 'Aconteceu algum erro durante o processo de exclusão. Tente novamente mais tarde.',
            },
            deactivate: {
              buttonSave: 'Desativar',
              questionMessage: 'Tem certeza que deseja desativar o registro?',
              successMessage: 'Registro desativado com sucesso!',
              errorMessage: 'Aconteceu algum erro durante o processo de desativação. Tente novamente mais tarde.',
            },
            start: {
              successMessage: 'Processo de execução do robô iniciado com sucesso!',
              errorMessage: 'Ocorreu um erro ao tentar iniciar o robô. Por favor, tente novamente.',
            },
            stop: {
              successMessage: 'Execução interrompida com sucesso!',
              errorMessage: 'Ocorreu um erro ao tentar parar. Por favor, tente novamente.',
            },
            refresh: {
              successMessage: 'Atualização realizada com sucesso!',
              errorMessage: 'Ocorreu um erro ao tentar atualizar. Por favor, tente novamente.',
            },
            validators: {
              requiredField: 'Este campo é obrigatório.',
              passwordMatch: 'Senhas devem ser iguais.',
              passwordContains:
                'Senha deve conter pelo menos um número, uma letra maiúscula e um caractere não alfanumérico.',
              onlyLettersOrNumbers: 'Deve conter apenas letras e números.',
              email: 'Preencha com um email válido.',
              minStartDate: 'A data de início não pode ser inferior a data atual.',
              minEndTime: 'A hora de término não pode ser inferior a hora de início.',
              minTwoWords: 'Preencha com pelo menos duas palavras.',
              minLength_5: 'O campo deve ter no mínimo 5 caracteres.',
              minLength_6: 'Deve conter pelo menos 6 caracteres.',
              minLength_15: 'Deve conter pelo menos 15 caracteres.',
              minLength_32: 'O campo deve ter no máximo 32 caracteres.',
              maxLength_25: 'O campo deve ter no máximo 25 caracteres.',
              maxLength_70: 'O campo deve ter no máximo 70 caracteres.',
              maxLength_512: 'O campo deve ter no máximo 512 caracteres.',
              maxLength_1024: 'O campo deve ter no máximo 1024 caracteres.',
            },
          },

          emptyOption: 'Selecione uma opção',

          searchInput: {
            placeholder: 'Buscar',
          },

          toolbar: {
            filter: 'Filtrar',
            filterOptions: 'Opções de Filtro',
            status: 'Status:',
            buttonApply: 'Aplicar',
            buttonCancel: 'Cancelar',
          },

          datatable: {
            pagination: {
              title: 'Linhas por página',
              of: 'de',
            },
            selectedItems: {
              all: 'Todos',
              singular: 'item selecionado', // ex. 1 item selecionado
              plural: 'itens selecionados', // ex. 8 itens selecionados
            },
            noData: 'Nenhum dado disponível',
          },

          schedules: {
            runningStatus: {
              Scheduled: 'Agendado',
              NotRunning: 'Sem Executar',
              Running: 'Executando',
              Success: 'Successo',
              Stopped: 'Parado',
              Error: 'Erro',
              Suspect: 'Sob Suspeita',
              Conflict: 'Sobreposição',
            },
          },

          daysOfWeek: {
            sunday: 'domingo',
            monday: 'segunda-feira',
            tuesday: 'terça-feira',
            wednesday: 'quarta-feira',
            thursday: 'quinta-feira',
            friday: 'sexta-feira',
            saturday: 'sábado',
          },

          enum: {
            agentlogstatus: {
              NotRunning: 'Sem executar',
              Running: 'Executando',
              Success: 'Sucesso',
              Stopped: 'Parado',
              Error: 'Erro',
              Suspect: 'Suspeito',
              Conflict: 'Conflito',
              Triggered: 'Disparado',
            },
          },

          time: {
            complete: {
              second: 'segundo',
              minute: 'minuto',
              hour: 'hora',
              day: 'dia',
              month: 'mês',
              year: 'ano',
            },
            abbreviated: {
              second: 'seg(s)',
              minute: 'min(s)',
              hour: 'hr(s)',
              day: 'dia(s)',
              month: 'mês(es)',
              year: 'ano(s)',
            },
          },

          alerts: {
            notification: 'Notificação',
            notifications: 'Notificações',
            noViewed: 'Não lidas(s)',
            department: 'Departamento(s)',
            empty: 'Sem notificações disponíveis',
            viewAll: 'Visualizar Todas',
          },
        },
      },
      es: {
        translation: {
          menu: {
            home: 'Hogar',
            dashboard: 'Panel',
            charts: 'Gráficos',
            projectStatus: 'Estado del proyecto',
            security: 'Seguridad',
            example: 'Muestras',
            crud: {
              title: 'Mierda',
              name: 'Nombre',
              decimalValue: 'Valor decimal',
              dateOfBirth: 'Fecha de nacimiento',
              age: 'Edad',
            },
            complexCrud: {
              title: 'CRUD complejo',
              name: 'Nombre',
              decimalValue: 'Valor decimal',
              dateOfBirth: 'Fecha de nacimiento',
              handicapped: 'Minusválido',
              termsAcceptance: 'Condiciones de aceptación',
              avatar: 'Imagen',
            },
            tenantManagement: {
              menu: 'Manejo de tenant',
              title: 'Manejo de tenant',
              add: 'Añadir tenant',
              edit: 'Editar tenant',
            },
            userManagement: {
              menu: 'Gestión de usuarios',
              title: 'Gestión de usuarios',
              add: 'Agregar usuario',
              edit: 'editar usuario',
            },
            groupManagement: {
              menu: 'Manejo de grupo',
              title: 'Manejo de grupo',
              add: 'Añadir grupo',
              edit: 'Editar grupo',
            },
            apiClients: 'Clientes API',
            settings: 'Configuración',
            userProfile: 'Perfil del usuario',
            generalSettings: 'Configuración general',
            basic: {
              title: 'CRUD básico',
              add: 'Agregar datos',
              edit: 'Editar datos',
            },
            complexdatabase: {
              menu: 'Base de datos CRUD compleja',
              title: 'Base de datos CRUD compleja',
              add: 'Agregar datos',
              edit: 'Editar datos',
            },
            complexfrontend: {
              menu: 'Frontend CRUD complejo',
              title: 'Frontend CRUD complejo',
              add: 'Agregar datos',
              edit: 'Editar datos',
            },
            robotType: {
              menu: 'Tipo de robot',
              title: 'Tipo de robot',
              add: 'Agregar tipo de robot',
              edit: 'Editar tipo de robot',
            },
            countries: {
              menu: 'Países',
              title: 'Países',
              add: 'Agregar país',
              edit: 'Editar país',
            },
            sites: {
              menu: 'Sitios',
              title: 'Sitios',
              add: 'Agregar sitio',
              edit: 'Editar sitio',
            },
            operationsystem: {
              menu: 'Sistema operativo',
              title: 'Sistema operativo',
              add: 'Agregar sistema operativo',
              edit: 'Editar sistema operativo',
            },
            departaments: {
              menu: 'Departamentos',
              title: 'Departamentos',
              add: 'Agregar departamento',
              edit: 'Departamento de edición',
            },
            processes: {
              menu: 'Proceso',
              title: 'Procesos',
              add: 'Agregar proceso',
              edit: 'Proceso de edición',
            },
            technologies: {
              menu: 'Tecnologías',
              title: 'Tecnologías',
              add: 'Añadir tecnología',
              edit: 'Editar tecnología',
            },
            machineType: {
              title: 'Tipo de máquina',
              add: 'Agregar tipo de máquina',
              edit: 'Editar tipo de máquina',
            },
            machine: {
              title: 'Máquina',
              add: 'Máquina sumadora',
              edit: 'Editar máquina',
            },
            errorreason: {
              menu: 'Razones de error',
              title: 'Razones de error',
              add: 'Agregar motivos de error',
              edit: 'Editar motivos de error',
            },
            executions: {
              menu: 'Ejecuciones',
              title: 'Ejecuciones',
            },
            agentVersions: 'Versiones del agente',
            events: 'Eventos',
            machineActivity: 'Actividad de la máquina',
            robotExecutions: 'Ejecuciones de robots',
            schedule: 'Cronograma',
            detailScreen: 'Pantalla de detalles',
            masterData: 'Datos maestros',
            infrastructure: 'Infraestructura',
            language: 'Idioma',
            preferences: 'Preferencias',
            general: 'General',
            signOut: 'Desconectar',
          },
          generalMessages: {
            yes: 'Sí',
            no: 'No',
            all: 'Todo',
            active: 'Activo',
            inactive: 'Inactivo',
            addUser: 'Agregar usuario',
            select: 'Seleccionar',
            selectAll: 'Seleccionar todo',
            sessionExpiresIn: 'La sesión expira en:',
            sessionExpired: 'Su sesión ha caducado. Por favor actualice la página.',
            signin: 'Iniciar sesión',
            connected: 'Conectado',
            disconnected: 'Desconectado',
            edit: 'Editar',
            delete: 'Borrar',
            deactivate: 'Desactivar',
            download: 'Descargar',
            apply: 'Aplicar',
            cancel: 'Cancelar',
            filter: 'Filtrar',
            filterOptions: 'Opciones de filtro',
            noOptions: 'No se encontró ninguna opción',
            status: 'Estado',
            start: 'Comenzar',
            stop: 'Detener',
            refresh: 'Actualizar',
            screenshot: 'Captura de pantalla',
            copy: 'Copiar',
            copied: '¡Copiado!',
            noPermission: 'No tienes permiso',
            execute: 'Ejecutar',
            errors: {
              503: 'La función a la que intenta acceder no está habilitada. Comuníquese con el administrador.',
            },
          },
          login: {
            title: 'Bienvenido a',
            form: {
              username: {
                label: 'Nombre de usuario',
                placeholder: 'Ingrese su nombre de usuario',
              },
              password: {
                label: 'Contraseña',
                placeholder: 'Ingresa tu contraseña',
              },
            },
            footer: 'Energizado por',
            loading: {
              title: 'Bienvenido',
              subtitle: 'Por favor espere mientras iniciamos sesión en su cuenta...',
            },
            success: '¡Inicio de sesión correcto!',
          },
          units: {
            prefix: null,
            thousandSeparator: null,
            decimalSeparator: null,
          },
          toolbar: {
            filter: 'Filtrar',
            filterOptions: 'Opciones de filtro',
            status: 'Estado:',
            buttonApply: 'Aplicar',
            buttonCancel: 'Cancelar',
          },
          dateFormat: {
            locale: 'es',
            momentTimezone: 'Estados Unidos/Nueva York',
          },
          topbar: {
            system: {
              version: 'Versión:',
            },
            userMenu: {
              language: 'Idioma',
              signOut: 'Desconectar',
              myProfile: 'Mi perfil',
            },
          },
          crud: {
            common: {
              loading: 'Cargando...',
              wait: 'Espere por favor...',
            },
            create: {
              buttonSave: 'Ahorrar',
              buttonCancel: 'Cancelar',
              successMessage: 'Entrada creada exitosamente',
              errorMessage: 'Se produjo un error al guardar las entradas. Inténtalo de nuevo más tarde.',
            },
            read: {
              errorMessage: 'Se produjo un error al intentar obtener la lista. Inténtelo nuevamente más tarde.',
            },
            update: {
              buttonSave: 'Ahorrar',
              buttonCancel: 'Cancelar',
              successMessage: 'Entrada actualizada exitosamente',
              errorMessage: 'Se produjo un error al cargar las entradas. Inténtalo de nuevo más tarde.',
            },
            delete: {
              buttonSave: 'Borrar',
              buttonCancel: 'Cancelar',
              questionMessage: '¿Estás seguro que deseas eliminar esta entrada?',
              successMessage: '¡Entrada eliminada exitosamente!',
              errorMessage: 'Se produjo un error al desactivar las entradas. Por favor, inténtelo de nuevo más tarde.',
            },
            deactivate: {
              buttonSave: 'Desactivar',
              questionMessage: '¿Está seguro de que desea desactivar el registro?',
              successMessage: '¡Registro desactivado exitosamente!',
              errorMessage: 'Se produjo un error durante el proceso de desactivación. Inténtalo de nuevo más tarde.',
            },
            download: {
              buttonSave: 'Descargar',
              questionMessage: '¿Estás seguro que deseas descargar el archivo?',
              successMessage: '¡Registro descargado exitosamente!',
              errorMessage: 'Se produjo un error durante el proceso de descarga. Inténtalo de nuevo más tarde.',
            },
            start: {
              successMessage: '¡El proceso de ejecución del robot comenzó exitosamente!',
              errorMessage: 'Se produjo un error al iniciar el robot. Inténtalo nuevamente.',
            },
            stop: {
              successMessage: '¡Ejecución interrumpida exitosamente!',
              errorMessage: 'Se produjo un error al intentar detenerlo. Inténtalo nuevamente.',
            },
            refresh: {
              successMessage: '¡Actualización completada exitosamente!',
              errorMessage: 'Se produjo un error al intentar actualizar. Inténtalo nuevamente.',
            },
            validators: {
              requiredField: 'Este campo es obligatorio.',
              passwordMatch: 'Las contraseñas deben coincidir.',
              passwordContains:
                'Las contraseñas deben tener al menos un número, una letra mayúscula y un carácter no alfanumérico.',
              onlyLettersOrNumbers: 'Debe contener sólo letras o números.',
              email: 'Debe ser un correo electrónico válido.',
              minStartDate: 'La fecha de inicio no puede ser menor que la fecha actual.',
              minEndTime: 'La hora final no puede ser menor que la hora de inicio.',
              minTwoWords: 'Debe tener al menos dos palabras.',
              minLength_5: 'Debe tener al menos 5 caracteres.',
              minLength_6: 'Debe tener como mínimo 6 caracteres.',
              minLength_15: 'Debe tener al menos 15 caracteres.',
              minLength_32: 'Debe tener como máximo 32 caracteres.',
              maxLength_25: 'Debe tener como máximo 25 caracteres.',
              maxLength_70: 'Debe tener como máximo 70 caracteres.',
              maxLength_512: 'Debe tener como máximo 512 caracteres.',
              maxLength_1024: 'Debe tener como máximo 1024 caracteres.',
            },
          },
          emptyOption: 'Seleccione una opción',
          searchInput: {
            placeholder: 'Buscar',
          },
          datatable: {
            pagination: {
              title: 'Filas por página',
              of: 'de',
            },
            selectedItems: {
              all: 'Todo',
              singular: 'item seleccionado',
              plural: 'artículos seleccionados',
            },
            noData: 'Datos no disponibles',
          },
          schedules: {
            runningStatus: {
              Scheduled: 'Programado',
              NotRunning: 'No correr',
              Running: 'Correr',
              Success: 'Éxito',
              Stopped: 'Interrumpido',
              Error: 'Error',
              Suspect: 'Bajo sospecha',
              Conflict: 'Cubrir',
            },
          },
          daysOfWeek: {
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado',
          },
          enum: {
            agentlogstatus: {
              NotRunning: 'No corras',
              Running: 'Correr',
              Success: 'Éxito',
              Stopped: 'Interrumpido',
              Error: 'Error',
              Suspect: 'Sospechar',
              Conflict: 'Conflicto',
              Triggered: 'Motivado',
            },
          },
          time: {
            complete: {
              second: 'segundos',
              minute: 'minutos',
              hour: 'horas',
              day: 'días)',
              month: 'meses',
              year: 'años',
            },
            abbreviated: {
              second: 'segundos',
              minute: 'minutos',
              hour: 'horas',
              day: 'días',
              month: 'meses',
              year: 'años',
            },
          },
          alerts: {
            notification: 'Notificación',
            notifications: 'Notificaciones',
            noViewed: 'No hay lectura(s)',
            department: 'Departamento(s)',
            empty: 'No hay notificaciones disponibles',
            viewAll: 'Ver todo',
          },
        },
      },
    },
  });

i18n.addResourceBundle('us', 'translation', en);
i18n.addResourceBundle('br', 'translation', pt);
i18n.addResourceBundle('es', 'translation', es);

export default i18n;
